import { makeStyles, Paper, styled } from '@material-ui/core'
import { db } from "../Firebase"
import { Container, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import play from "../images/play.png"
import ios from "../images/ios.png"
import React, { useState, useEffect } from 'react'
import "./LinearProgress.css"
import LinearProgress from '@mui/material/LinearProgress';
const useStyles = makeStyles(theme => ({
    box: {
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: "20px !important",
        padding: "50px",
        marginTop: "100px",
        boxShadow: "0 0 13px rgb(0 0 0 / 20%)",
    },
    section: {
        padding: "10px 0",
    },
    featureGamesTitle: {
        fontWeight: "700 !important",
        fontSize: "24px !important",
        lineHeight: "29px !important",
        color: "#06214a",
        paddingBottom: "50px",
        paddingLeft: "5%"
    },
    featureImg: {
        width: "140px",
        height: "140px",
        borderRadius: "20px"
    },
    featureTitle: {
        marginTop: "10px  !important",
        fontSize: "16px  !important",
        lineHeight: "20px  !important",
        whiteSpace: "nowrap !important",
        overflow: "hidden  !important",
    },
    featureRank: {
        marginTop: "10px  !important",
        fontSize: "13px  !important",
        lineHeight: "16px  !important",
        fontWeight: "700 !important",
        color: "#06214a"
    },
    featureDownloads: {
        fontSize: "11px  !important",
        lineHeight: "13px  !important",
        paddingTop: "5px",
        marginBottom: "25px",
        color: "#7295ca",
    },

    item: {
        margin: "30px 10px",
        position: "relative",
        border: "1px solid #fff",
        boxShadow: "none",
        padding: "20px 0 !important",
        '&:hover': {
            boxShadow: "0 0 4px rgb(0 0 0 / 25%)",
            border: "1px solid #d3d3d3",
            borderRadius: "10px",
        },
        '&:hover $anchorBtn': {
            display: "flex"
        },


    },
    anchorBtn: {
        maxWidth: "50%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
        display: "none"
    },
    iosplay: {
        backgroundColor: "#000",
        border: "1px solid #000",
        borderRadius: "5px",
        margin: "10px 0"
    },
    blurBg: {
        '&:hover': {
            filter: "blur(2px)"
        },
    },
    notYet: {
        padding: "50px",
        paddingLeft: "8%",
        color: "grey"
    }

}))

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const AllGameList = () => {
    const classes = useStyles();
    const [currentID, setCurrentID] = useState()
    const [featured, setFeatured] = useState([])
    const [loading, setLoading] = useState(true);

    const fetchFeatureGames = async () => {
        const getPostFromFirebase = []
        db
        .collection('FeaturedGames')
        .get()
        .then(docs => {
          docs.forEach(doc => {
            const data = doc.data()

            // adding new property id with id from Firestore
            data.id = doc.id
            
            getPostFromFirebase.push(data)
          })
          setFeatured(getPostFromFirebase)
          setLoading(false);
        })
        .catch(err => console.log(err))

    }
    const onDelete = () => {
        db.collection('FeaturedGames').doc(currentID).delete()
    };
    useEffect(() => {
        fetchFeatureGames()
    })

    const [openBtn, setOpenBtn] = useState(true)
    return (
        <section className={classes.section}>
            <Container>
                <Box className={`${classes.box} gamelistAll`}>
                    <Typography variant="h4" className={classes.featureGamesTitle}>Features Games</Typography>
                    {loading ? (<LinearProgress style={{ color: "#000", backgroundColor: "rgba(0, 0, 0, 0.2)" }} className='progessLinear' />) : null}
                    <Grid container spacing={4}>
                        {
                            featured.length > 0 ? (
                                featured && featured.map((featuredItem, index) => {
                                    // if (index < 9) {
                                    //     if (featuredItem.topRated === "topRated") {
                                    return (
                                        <Grid item xs={12} md={3} key={featuredItem.id} >
                                            <Item className={classes.item} >
                                                <Box className={classes.blurBg}>
                                                    <img src={featuredItem.imageUrl} className={classes.featureImg} />
                                                    <Typography className={classes.featureTitle} variant="h4">{featuredItem.title}</Typography>
                                                    {/* <Typography className={classes.featureRank} variant="h4">{featuredItem.rank}</Typography> */}
                                                </Box>

                                                <Box className={classes.anchorBtn} >
                                                    {featuredItem.appleLink !== "" ? (<a href={featuredItem.appleLink}><img src={ios} className={classes.iosplay} /></a>) : null}
                                                    {featuredItem.playLink !== "" ? (<a href={featuredItem.playLink}><img src={play} className={classes.iosplay} /></a>) : null}
                                                </Box>
                                            </Item>
                                        </Grid>
                                    )
                                })
                            ) : (<h3 className={classes.notYet}>Not Featured Games Yet</h3>)
                        }
                    </Grid>

                </Box>
            </Container>
        </section>
    )
}
