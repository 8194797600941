import { makeStyles } from '@material-ui/core';
import { Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import logo from '../logob.png';
import logo1 from '../logow.png';

const useStyles = makeStyles(theme => ({
    aboutUs: {
        padding: "100px 0 !important",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            height: "auto",
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        }
    },
    leoruslogo: {
        height: "280px",
        width: "280px",
        [theme.breakpoints.down("md")]: {
            margin: "45px 0 0  !important",
            height: "230px",
            width: "230px",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0  !important",
            height: "150px",
            width: "150px",
        },

    },
    leoruslogo1: {
        height: "280px",
        width: "280px",
        display: "none",
        [theme.breakpoints.down("md")]: {
            // margin: "45px 0 0  !important",
            height: "230px",
            width: "230px",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0 auto !important",
            height: "150px",
            width: "150px",
        },
    },
    aboutTitle: {
        fontSize: " 24px!important",
        fontFamily: "'Kanit', sans-serif",
        paddingBottom: "14px",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        }
    },
    aboutDescription: {
        fontSize: "19px",
        fontWeight: 400,
        lineHeight: "40px",
        fontFamily: "'Kanit', sans-serif",
        padding: 0,
        textAlign: "justify",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: "16px",
        }
    },
    gridLogo: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        }
    }

}))
export const About = () => {
    const classes = useStyles();
    return (
        <div id="about" className={`${classes.aboutUs} ${"aboutUs"}`} >
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} className={classes.gridLogo}>
                            <img src={logo} alt="leorus game" className={`${classes.leoruslogo} ${"leoruslogo"}`} />
                            <img src={logo1} alt="leorus game" className={`${classes.leoruslogo1} ${"leoruslogo1"}`} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box>
                                <h2 className={classes.aboutTitle}>About Us</h2>
                                <p className={classes.aboutDescription}>
                                    We are a mobile game developer and studio based in Lahore, Pakistan. With a team consisting of passionate and creative minds, our aim is to create mobile games that can be enjoyed by anyone!
                                    From developing high-quality mobile games to publishing the next hit title, we do everything in-house at our studio. Since the time we were founded in 2021, we published multiple mobiles games in the Hyper Casual genre.
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
