import { makeStyles } from '@material-ui/core';
import { Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import joinw from '../images/joinw.svg';



const useStyles = makeStyles(theme => ({
    career: {
        padding: "100px 0",
        height: "100vh",
        width: "100vw",
        alignItems:"center",
        display:"flex",
        [theme.breakpoints.down("md")]: {
            padding: "11.25rem 0",
            textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "6.25rem 0",
            textAlign: "center",
        },
    },
    logo: {
        height: "280px",
        width: "280px",
    },
    careerTitle: {
        fontSize: " 24px!important",
        paddingBottom: "14px",
        fontFamily: "'Kanit', sans-serif",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },
    careerDescription: {
        fontSize: "19px",
        fontWeight: 400,
        width: "700px",
        display: "flex",
        lineHeight: "40px",
        padding: 0,
        textAlign: "justify",
        fontFamily: "'Kanit', sans-serif",
        [theme.breakpoints.down("md")]: {
            display: "block",
            width: "500px",
            textAlign: "center",
            margin:"0 auto"
        },
        [theme.breakpoints.down("sm")]: {
            width: "auto",
            display: "block",
            fontSize: "16px",
            textAlign: "center",
        },
    },
    joinus: {
        border: " 2px solid #000",
        color: "#000",
        borderRadius: "30px",
        padding: " 10px 42px",
        display: "inline-block",
        fontWeight: "700!important",
        fontSize: "13px",
        fontFamily: "'Kanit', sans-serif",
        textAlign: "center",

        '&:hover': {
            color: "#fff",
            backgroundColor: "#000",
            transition: "all 300ms ease-in-out"
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        }
    },
    joinw: {
        backgroundColor: "#fff",
        width: "400px",
        borderRadius: " 10px",
        height: "300px",
        [theme.breakpoints.down("sm")]: {
            display:"none"
        }
    },

}))
export const Career = () => {
    const classes = useStyles();
    return (
        <div id="career" className={classes.career} >
            <Container>
                <Grid container spacing={4}>

                    <Grid item xs={12} md={12} lg={8}>
                        <Box>
                            <h2 className={classes.careerTitle}>Career</h2>
                            <p className={classes.careerDescription}>
                                Check out our open positions and take your first step to become a part of the Leorus Games team. Unleash your potential and shine with us!
                            </p>
                        </Box>
                        <Box style={{ paddingTop: "20px" }}>
                            <a href="https://linkedin.com/company/leorusgames" target="_blank" className={`${classes.joinus} ${"joinus"}`} variant="outlined">Join Us</a>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <img src={joinw} alt="leorus game" className={`${classes.joinw} ${"joinw"}`} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
