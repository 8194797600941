import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import "../components/Banner.css"
import { makeStyles } from '@material-ui/core'
import { db, } from "../Firebase"
import Typewriter from "typewriter-effect";
import TypeAnimation from 'react-type-animation'
const useStyles = makeStyles(theme => ({
  Banner: {
    [theme.breakpoints.down("md")]: {
      '& .bHeading': {
        width: "354px"
      },
      '& .Typewriter ': {
        fontSize: "2.3rem",
      }
      ,
      '& .message ': {
        fontSize: "2rem !important",
        left: "90px",
      }

    },
    [theme.breakpoints.down("sm")]: {
      '& .bHeading': {
        width: "354px"
      },
      '& .Typewriter ': {
        fontSize: "1.8rem !important",
      },
      '& .message ': {
        fontSize: "2rem",
        left: "90px",
      }
    },
  },
  bHtext1: {
    [theme.breakpoints.down("sm")]: {
      '&::after': {
        fontSize: "2rem",
        letterSpacing: "5px",
        margin: "0   20px",

      },
      '&::before': {
        fontSize: "2rem",
        letterSpacing: "5px",
        top: "9%",
      },
    }
  },
  bHtext2: {
    [theme.breakpoints.down("sm")]: {
      '&::after': {
        fontSize: "2rem",
        letterSpacing: "5px",
        top: "9%",
      },
      '&::before': {
        fontSize: "2rem",
        letterSpacing: "5px",
        margin: "0   20px",

      },
    }
  },

  flowingIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  }
}))
export const Banner = () => {

  const [currentID, setCurrentID] = useState()
  const [featured, setFeatured] = useState([])
  const [loading, setLoading] = useState(true);

  const fetchFeatureGames = async () => {
    const getPostFromFirebase = []
    db
    .collection('FeaturedGames')
    .get()
    .then(docs => {
      docs.forEach(doc => {
        const data = doc.data()

        // adding new property id with id from Firestore
        data.id = doc.id
        
        getPostFromFirebase.push(data)
      })
      setFeatured(getPostFromFirebase)
      setLoading(false);
    })
    .catch(err => console.log(err))

  }
  useEffect(() => {
    fetchFeatureGames()

  })
  const classes = useStyles();
  return (
    <Box className={`${classes.Banner} ${"banner"}`} >
      {/* <h1 className='bHeading'>
        {/* <span className='we'>We</span>
        <div class="message">
          <div class="word1">Develop</div>
          <div class="word2">Create</div>
          <div class="word3">Design</div>
        </div> */}
      <Typewriter
        className='we'
        options={{
          strings: ['WE DEVELOP', 'WE CREATE', 'YOU PLAY'],
          autoStart: true,
          loop: true,
        }}
      />

      {/* </h1> */}
      <Box className="background">
        {

          featured.length > 0 ? (
            featured && featured.map((featuredItem, index) => {
              if (index < 8) {
                return (
                  <span className={classes.flowingIcon}  key={featuredItem.id}>
                    <a href={featuredItem.videoLink} target="_blank" rel="noreferrer" >
                      <img src={featuredItem.imageUrl} className="bannerIcon" />

                    </a>
                  </span>
                )
              }
            })

          ) : (<h3></h3>)
        }
      </Box>
    </Box>
    // <div id="home" className={`${classes.Banner} ${"banner"}`}>
    //   <div className="bannerText">
    //       <div className="bText">
    //         <h2 className={` ${"bHtext1"} ${classes.bHtext1}`}></h2><h2 className={` ${"bHtext2"} ${classes.bHtext2}`}></h2>
    //       </div>
    //   </div>
    /* <Box className="background">
      {
        featured.length > 0 ? (
          featured && featured.map((featuredItem, index) => {
            return (
              <span className={classes.flowingIcon}>
                <a href={featuredItem.videoLink} target="_blank">
                  <img src={featuredItem.imageUrl} className="bannerIcon" />

                </a>
              </span>
            )
          })
        ) : (<h3></h3>)
      }

    </Box> */
    // </div>


  )
}
