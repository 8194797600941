import React, { useState, useEffect } from 'react'
import AdminLogin from './AdminLogin';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from "../Firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth"
import { Alert, LinearProgress, Snackbar } from '@mui/material';
import { FeaturedGames } from './FeaturedGames';
import { AddFeaturedGames } from './AddFeaturedGames';
export const AdminBase = () => {
    const [user, setuser] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleLogin = async () => {
        console.log("kkkkk");
        try {
            const user = await signInWithEmailAndPassword(
                auth,
                email,
                password,
            );
        }
        catch (error) {

            setOpen(true);
        }
    }
    const authListener = () => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
                setOpen(true);
                setLoading(false)
            }
            else {
                setuser("")
                setLoading(false)
            }
        })

    }

    useEffect(() => {
        authListener();
    }, [])


    return (
        <div >

            {user ? (
                <>
                    <AddFeaturedGames />
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Sucessfullu login
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    {loading ? (

                        <LinearProgress value={loading} />

                    ) : (
                        <AdminLogin
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                            handleLogin={handleLogin}
                            open={open}
                            setOpen={setOpen}

                        />

                    )}

                </>
            )}

        </div>
    )

}
export default AdminBase