import { Button, makeStyles, Paper, styled } from '@material-ui/core'
import { db } from "../Firebase"
import { Container, Grid, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import play from "../images/play.png"
import ios from "../images/ios.png"
import "./LinearProgress.css"
import React, { useState, useEffect } from 'react'
import {
    Link,
} from "react-router-dom";
const useStyles = makeStyles(theme => ({
    box: {
        backgroundColor: "#fff",
        borderRadius: "20px !important",
        padding: "50px",
        margin: "100px 0",
        boxShadow: "0 0 13px rgb(0 0 0 / 20%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        '& .css-iv7vbz-MuiGrid-root': {
            padding: "20px !important",
        },
        [theme.breakpoints.down("md")]: {

        },
        [theme.breakpoints.down("sm")]: {
            padding: "25px",
            '&   .css-480o17-MuiGrid-root': {
                margin: "0",
            }
        },

    },
    featuredSection: {
        backgroundColor: "#fff",
        padding: "10px 0",
        // borderTop: " 1px solid #e1e4e8",
    },
    featureGamesTitle: {
        fontWeight: "700 !important",
        fontSize: "24px !important",
        lineHeight: "29px !important",
        color: "#06214a",
        paddingBottom: "50px",
        paddingLeft: "5%",
        fontFamily: "'Kanit', sans-serif !important",
        [theme.breakpoints.down("sm")]: {
            padding: "25px",
            fontSize: "18px !important",
        },
    },
    featureImg: {
        width: "140px",
        height: "140px",
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
            width: "100px",
            height: "100px",
        },

    },
    featureTitle: {
        marginTop: "10px  !important",
        fontSize: "16px  !important",
        lineHeight: "20px  !important",
        whiteSpace: "nowrap !important",
        fontFamily: "'Kanit', sans-serif !important",
        overflow: "hidden  !important",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px  !important",
            marginBottom:"10px"
        },
    },
    featureRank: {
        marginTop: "10px  !important",
        fontSize: "13px  !important",
        lineHeight: "16px  !important",
        fontWeight: "700 !important",
        color: "#06214a",
        fontFamily: "'Kanit', sans-serif !important",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px  !important",
        },
    },
    featureDownloads: {
        fontSize: "11px  !important",
        lineHeight: "13px  !important",
        fontFamily: "'Kanit', sans-serif",
        paddingTop: "5px",
        marginBottom: "25px",
        color: "#7295ca",
    },

    item: {
        margin: "30px 10px",
        position: "relative",
        border: "1px solid #fff",
        boxShadow: "none",
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: "20px 0 !important",
        '&:hover': {
            boxShadow: "0 0 4px rgb(0 0 0 / 25%)",
            border: "1px solid #d3d3d3",
            borderRadius: "10px",
            width:"90%",
            margin:"0 auto"
        },
        '&:hover $anchorBtn': {
            display: "flex"
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0",
            margin: "0"
        },


    },
    anchorBtn: {
        maxWidth: "50%",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "auto",
        display: "none"
    },
    iosplay: {
        backgroundColor: "#000",
        border: "1px solid #000",
        borderRadius: "5px",
        margin: "10px 0"
    },
    blurBg: {
        '&:hover': {
            filter: "blur(2px)"
        },
    },
    notYet: {
        padding: "50px",
        paddingLeft: "8%",
        color: "grey"
    },
    seeMore: {
        border: " 2px solid #000",
        color: "#000",
        borderRadius: "30px",
        padding: " 10px 42px",
        display: "inline-block",
        fontWeight: "700!important",
        textAlign: " center",
        fontSize: "13px",
        fontFamily: "'Kanit', sans-serif",
        textAlign: "center",

        '&:hover': {
            color: "#fff",
            backgroundColor: "#000",
            transition: "all 300ms ease-in-out"
        }
    }

}))

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const FeaturedGames = () => {
    const classes = useStyles();
    const [featured, setFeatured] = useState([])
    const [loading, setLoading] = useState(true);

    const fetchFeatureGames = async () => {
        const getPostFromFirebase = []
        db
        .collection('FeaturedGames')
        .get()
        .then(docs => {
          docs.forEach(doc => {
            const data = doc.data()

            // adding new property id with id from Firestore
            data.id = doc.id
            
            getPostFromFirebase.push(data)
          })
          setFeatured(getPostFromFirebase)
          setLoading(false);
        })
        .catch(err => console.log(err))
    }
    useEffect(() => {
        fetchFeatureGames()
    })

    const [openBtn, setOpenBtn] = useState(true)
    return (
        <section className={`${classes.featuredSection} ${"featuredSection"}`}>
            <div className="">
                <Container>
                    <Box className={`${classes.box} ${"box"}`}>
                        {loading ? (<LinearProgress  style={{ color: "#000", backgroundColor: "rgba(0, 0, 0, 0.2)" }} className='progessLinear' />) : null}
                        <Typography variant="h4" className={`${classes.featureGamesTitle} ${"featureGamesTitle"}`}>Features Games</Typography>
                        <Grid container spacing={4}>
                            {
                                featured.length > 0 ? (
                                    featured && featured.map((featuredItem, index) => {
                                        if (index < 8) {
                                            if (featuredItem.rank === "Featured") {
                                                return (
                                                    <Grid item xs={12} md={3}  key={featuredItem.id} >
                                                        <Item className={`${classes.item} ${"item"}`} >
                                                            <Box className={classes.blurBg}>
                                                                <img src={featuredItem.imageUrl} className={classes.featureImg} />
                                                                <Typography className={classes.featureTitle} variant="h4">{featuredItem.title}  {featuredItem.currentID}</Typography>
                                                                {/* <Typography className={classes.featureRank} variant="h4">{featuredItem.rank}</Typography>
                                                                <Typography className={classes.featureDownloads} variant="h4">{featuredItem.downloads}</Typography> */}
                                                            </Box>

                                                            <Box className={classes.anchorBtn} >
                                                                {featuredItem.appleLink !== "" ? (<a href={featuredItem.appleLink} target="_blank"><img src={ios} className={classes.iosplay} /></a>) : null}
                                                                {featuredItem.playLink !== "" ? (<a href={featuredItem.playLink} target="_blank" ><img src={play} className={classes.iosplay} /></a>) : null}
                                                            </Box>
                                                        </Item>
                                                    </Grid>
                                                )

                                            }



                                        }
                                    })
                                ) : (<h3 className={classes.notYet}>Not Featured Games Yet</h3>)
                            }
                        </Grid>
                        <Box style={{ textAlign: "center", paddingTop: "20px" }}>
                            <Link to="/gamelist"><Button className={`${classes.seeMore} ${"seeMore"}`} variant="outlined">See More</Button></Link>
                        </Box>
                    </Box>
                </Container>
            </div>

        </section >
    )
}
