import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LinearProgress, Snackbar } from '@mui/material';
import { db, auth } from "../Firebase"
import { makeStyles,TextField,Select } from '@material-ui/core';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UpdateIcon from '@mui/icons-material/Update';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Alert, CardActionArea, CardContent, CardMedia, Container, Fab, FormLabel, Grid, MenuItem } from '@mui/material';
import CircularProgressWithLabel from './PercentSpinner';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { app, storage } from '../Firebase';
import { Card, CardActions, FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import CircularStatic from './PercentSpinner';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const useStyles = makeStyles(theme => ({

    tfeatureImg: {
        width: "100px",
        height: "100px",
        borderRadius: "10px",
    },
    tableContianer: {
        margin: "100px auto !important",
        width: "95% !important",
        '& $Table': {
            backgroundColor: "#000 !important",
        },
        '& $Table th': {
            color: "#fff !important",
            fontFamily: "Kanit, sans-serif",
            fontSize: "16px",
            fontWeight: "600",

        },
        '& $Table td': {
            color: "#fff !important",
            fontFamily: "Kanit, sans-serif",
            fontSize: "13px",
            fontWeight: "400",
        }
    },




    fabAdd: {
        position: "fixed",
        right: 20,
        bottom: 20,
    },
    fab: {
        backgroundColor: "#000  !important",
        color: "#fff  !important",
    },
    modalContainer: {
        width: "500px !important",
        height: "550px !important",
        backgroundColor: "#fff !important",
        position: "absolute !important",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto !important",
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
            width: "100vw  !important",
            height: "100vh  !important",
            borderRadius: "0",
        }
    },
    form: {
        width: "100% !important",
    },
    item: {
        width: "100% !important",
        marginTop: theme.spacing(3),
    },
    text: {
        width: "100% !important",
        height: "40px !important",
        backgroundColor: "#fff",
        borderRadius: "10px",
        '&:focus': {
            outline: "0!important",
        },
        // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ': {
        //     borderColor: "#000",
        // },
        // '& .MuiInputLabel-outlined': {
        //     color: "#000",
        //     backgroundColor: "#fff",
        //     padding: "0 5px 0 ",
        //     // zIndex:"1",
        //     borderRadius: "3px 3px 0 0"
        //     // margin:"0 10px"
        // },
        // '& .PrivateNotchedOutline-legendLabelled-31 ': {
        //     display: "none"
        // },
        // '&  .MuiOutlinedInput-notchedOutline ': {
        //     borderColor: "#000  !important",
        // }
    },

    uploadbtn: {
        display: "flex",
        alignItems: "center ",
        margin: " 20px 0 0 !important",
        // justifyContent: " space-between",
        position: "relative",
    },
    uploadIcon: {
        height: "2em !important",
        width: "2em !important",
        color: "#fff !important",
        background: "#000 !important",
        borderRadius: " 50% !important",
        padding: "10px !important",
        position: "relative",
        zIndex: "9",

    },
    backBtn: {
        backgroundColor: "#000 !important",
        color: "#fff  !important",
        borderColor: "#fff  !important",

        '&:hover': {
            backgroundColor: "#fff !important",
            color: "#000  !important",
            borderColor: "#000  !important",
        },
    },
    logoutBtn: {
        backgroundColor: "#000 !important",
        borderRadius: "0 50% !important",
        padding: "10px !important",
        border: "1px solid #fff !important",
        '&:hover': {
            backgroundColor: "#fff !important",
            border: "1px solid #000 !important",
        },
        '&:hover $logoutIcon': {
            color: "#000 !important",
        },

    },
    logoutIcon: {
        color: "#fff !important",
    },
    cirPBar: {
        position: "absolute !important",
        top: "-4px !important",
        left: "-5px !important",
        width: "57px",
        height: "57px",
    },
}))
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function AdminTable() {
    const [currentID, setCurrentID] = useState()
    const [featured, setFeatured] = useState([])
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [openBtn, setOpenBtn] = useState(false)
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const [title, setTitle] = useState("")
    const [rank, setRank] = useState("")
    const [downloads, setDownload] = useState("")
    const [appleLink, setAppleLink] = useState("")
    const [playLink, setPlayLink] = useState("")
    const [videoLink, setVideoLink] = useState("")
    const [topRated, setTopRated] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [Error, setError] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);

    const alertHandleClick = () => {
        setOpenAlert(true);
        setOpenModal(false);
    };
    const alertHandleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };
    const handleLogout = () => {
        auth.signOut();
    }
    const [openError, setOpenError] = useState(false);

    const handleClickError = () => {
        setOpenError(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };
    const classes = useStyles();
    const fetchData = async () => {
        const data = await db.collection("FeaturedGames").get();
        setFeatured(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoading(false)
    };
    useEffect(() => {
        fetchData()

    }, [featured])

    const FeaturedTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value)
    }
    const FeaturedAppleLink = (e) => {
        setAppleLink(e.target.value)
    }
    const FeaturedPlayLink = (e) => {
        setPlayLink(e.target.value)
    }
    const FeaturedVideoLink = (e) => {
        setVideoLink(e.target.value)
    }
    const [progress, setProgress] = useState(0);
    const formHandler = () => {

        if (progress === 100) {
            db.collection("FeaturedGames").add({
                title: title,
                rank: rank,
                downloads: downloads,
                appleLink: appleLink,
                playLink: playLink,
                videoLink: videoLink,
                imageUrl: imageUrl
            })
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);

                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                });
            setOpenAlert(true);
            setOpenModal(false);
        }
        else {
            setOpenError(true);
            setOpenModal(true);
        }
    };
    const [isDisabled, setIsDisabled] = useState(false);
    const fileSelectedHandler = (e) => {
        setImageUrl(null)
        setIsDisabled(true)
        const file = e.target.files[0]
        if (!file) return;
        const sotrageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(sotrageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prog = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(prog);
            },
            (error) => console.log(error),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("File available at", downloadURL);
                    setImageUrl(downloadURL)
                    setIsDisabled(false)
                });

            }
        );


    }
    const [getIndex, setGetIndex] = useState()
    const [updateId, setUpdateId] = useState()

    const onHandleUpdate = () => {


        if (progress === 100) {
            db.collection('FeaturedGames').doc(updateId).set({ ...updateId, imageUrl, title, rank, appleLink, playLink, videoLink })
            setOpenModal(false);
            setIsDisabled(false)
        }
        else if (imageUrl !== "") {
            db.collection('FeaturedGames').doc(updateId).set({ ...updateId, imageUrl, title, rank, appleLink, playLink, videoLink })
            setOpenModal(false);
        }
        else {
            setOpenModal(true)
            setOpenError(true);
            console.log("update error");
        }
    }



    return (
        <>
            <TableContainer component={Paper} className={`${classes.tableContianer} ${"tableContianer"}`}>
                {loading ? (<LinearProgress  />) : null}
                <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                    <TableHead className={`${classes.tHead}${"tHead"}`}>
                        <TableRow className={`${classes.tRow}${"tRow"}`}>
                            <TableCell>Image</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Rank</TableCell>
                            <TableCell>Android Link</TableCell>
                            <TableCell>Ios Link</TableCell>
                            <TableCell>Youtube Link</TableCell>
                            <TableCell>Update</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            featured.length > 0 ? (
                                featured && featured.map((featuredItem, index) => {

                                    const onDelete = () => {
                                        db.collection('FeaturedGames').doc(featuredItem.id).delete()
                                        console.log(featuredItem.id, "delete id");
                                    }
                                    const onUpdate = async () => {
                                        setOpenModal(true)
                                        setGetIndex(featured[index]);
                                        setUpdateId(featuredItem.id);
                                        console.log(updateId, "uuuu");
                                        console.log(index, "index")
                                        setTitle(featuredItem.title);
                                        setRank(featuredItem.rank);
                                        setDownload(featuredItem.downloads);
                                        setPlayLink(featuredItem.playLink);
                                        setAppleLink(featuredItem.appleLink);
                                        setVideoLink(featuredItem.videoLink);
                                        setImageUrl(featuredItem.imageUrl);
                                    }


                                    return (
                                        <TableRow>
                                            <TableCell key={featuredItem.id}><img src={featuredItem.imageUrl} className={`${classes.tfeatureImg} ${"tfeatureImg"}`} /></TableCell>
                                            <TableCell>{featuredItem.title}</TableCell>
                                            <TableCell>{featuredItem.rank}</TableCell>
                                            <TableCell>{featuredItem.playLink}</TableCell>
                                            <TableCell>{featuredItem.appleLink}</TableCell>
                                            <TableCell>{featuredItem.videoLink}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Update">
                                                    <UpdateIcon onClick={onUpdate} />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Delete">
                                                    <DeleteForeverIcon onClick={onDelete} />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>

                                    )
                                })
                            ) : (<h3 className={classes.notYet}>Not Featured Games Yet</h3>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                >
                    <Container className={`${classes.modalContainer} modalContainer} `}>
                        {/* {
                            // getIndex ? (
                            featured.length > 0 ? (
                                featured && featured.map((featuredItem, index) => {
                                    return ( */}
                        <form className={classes.form} key={getIndex}>
                            <div className={`${classes.uploadbtn} ${"uploadbtn"}`}>
                                <input type="file" accept="image/*" id="file1" onChange={fileSelectedHandler} style={{ display: "none" }} />
                                <label for="file1"><CloudUploadIcon className={`${classes.uploadIcon} ${"uploadIcon"}`} /></label>
                                <CircularStatic className={`${classes.cirPBar} ${"cirPBar"}`} value={progress} />
                            </div>
                            <div className={classes.item}>
                                <TextField
                                    className={classes.text}
                                    style={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    id="outlined-multiline-static"
                                    label="Title"
                                    value={title}
                                    onChange={FeaturedTitle}
                                    error={Error}
                                    helperText={Error ? 'Title Already exists !' : ' '}
                                />

                            </div>
                            <div className={classes.item}>
                            <Select
                                className={classes.text}
                                style={{ width: "100%" }}
                                variant="outlined"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Rank"
                                value={rank}
                                notched={false}
                                onChange={(e) => setRank(e.target.value)}
                            >
                                <MenuItem value={"Normal"}>Normal</MenuItem>
                                <MenuItem value={"Featured"}>Featured</MenuItem>
                            </Select>
                            </div>
                            <div className={classes.item}>
                                <TextField
                                    className={classes.text}
                                    style={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    id="outlined-multiline-static"
                                    label="Apple store Link"
                                    value={appleLink}
                                    onChange={FeaturedAppleLink}
                                />
                            </div>
                            <div className={classes.item}>
                                <TextField
                                    className={classes.text}
                                    style={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    id="outlined-multiline-static"
                                    label="Play store Link"
                                    value={playLink}
                                    onChange={FeaturedPlayLink}
                                />
                            </div>
                            <div className={classes.item}>
                                <TextField
                                    className={classes.text}
                                    style={{ width: "100%" }}
                                    size="small"
                                    variant="outlined"
                                    id="outlined-multiline-static"
                                    label="Youtube store Link"
                                    value={videoLink}
                                    onChange={FeaturedVideoLink}
                                />
                            </div>
                            <div className={classes.item}>
                                <Button variant="outlined" className={`${classes.backBtn} ${"backBtn"}`} style={{ marginRight: 20 }} onClick={onHandleUpdate}  disabled={isDisabled} >Update</Button>
                                <Button variant="outlined" className={`${classes.backBtn} ${"backBtn"}`} style={{ marginRight: 20 }} onClick={handleClose}>Cancel</Button>
                            </div>
                        </form>

                        {/* //             )
                        //         })
                        //     ) : (<h3 className={classes.notYet}>Not Featured Games Yet</h3>)
                        // } */}


                    </Container>
                </Modal>
                <Snackbar open={openAlert} autoHideDuration={4000} onClose={alertHandleClose}>
                    <Alert onClose={alertHandleClose} severity="success" sx={{ width: '100%' }}>
                        Success Fully Added
                    </Alert>
                </Snackbar>
                <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                        Please Enter Image
                    </Alert>
                </Snackbar>
            </div>

        </>

    );
}